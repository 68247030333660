import { Link } from 'src/shared/ng-models/Link.interface';
import { Component} from '@angular/core';
import { ITenantReport } from 'src/libs/api/settings/settings.service';
import FrontEndSettingsUtil from 'src/shared/utils/frontEndSettings.util';

@Component({
  selector: 'mi-variance-report',
  template: `
  <div *ngIf="metaBaseDashboardId; else loading">
      <mi-page-header 
        title="Variance Reporting" 
        [breadcrumbs]="breadCrumbs" 
        [links]="headerLinks">
      </mi-page-header>
      <mi-align-metabase metaBaseDashboardId="{{metaBaseDashboardId}}"></mi-align-metabase>
    </div>
    <ng-template #loading>
    <mi-loading-animation></mi-loading-animation>
    </ng-template>
`,
})

export class VarianceReportComponent  {
  metaBaseDashboardId: number;
  showLoader: boolean = true;
  tenantId: string = null;
  frontEndSettings: ITenantReport[] = FrontEndSettingsUtil.get().TENANT_REPORTS || [];
  headerLinks: Link[] = [];

  breadCrumbs: Link[] = [
    {
      label: "Dashboard",
      url: "/",
      internal: true
    },
    {
      label: "Variance Reporting",
      url: "/variance-report",
      internal: true
    }
  ];

  ngOnInit(): void {
    const report = this.frontEndSettings.find( report => report.reportName === "Variance Reporting");

    if (report) {
      this.metaBaseDashboardId = report.metabaseDashboardId;
    } else {
      console.warn("Report with name 'Variance Reporting' not found.");
    }
    
  }

}
